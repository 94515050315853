<template>
  <div class="container overview-detail-list">
    <div class="row overview-detail-list-row">
      <div class="head overview-detail-list-head">
        <h3>
          {{overview_data.title_text|| overview_data.title}}
        </h3>
      </div>
      <template v-if="overview_list.length > 0 ">
        <div class="col-12  overview-list-container">
          <div class="row overview-list">

            <div class="col-6  overview-list-card-header" v-for="( overview, index) in overview_list" :key="'overview_' + index">
                <div class="card overview-list-card">
                  <img class="card-img-top overview-list-card-top" :src="image_url + overview.image" :alt="overview.title">
                  <div class="card-body overview-list-card-body">
                    <h4 class="card-title">{{overview.title}}</h4>
                  </div>
                  <div class="card-body read-more-card-body overview-child" v-if="overview.child && overview.child.length > 0">
                    <a @click="showOverviewChild(overview)" class="card-link" >
                      {{$t('core.Base.READ_MORE')}}
                    </a>
                  </div>
                  <div class="card-body read-more-card-body without-overview-child" v-else>
                    <router-link tag="a" :to="'overview/' + overview.id" class="card-link base-card-read-more" >
                      {{$t('core.Base.READ_MORE')}}
                    </router-link>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </template>
      <button type="button" @click="backScreen" class="btn btn-theme back-button-overview" v-if="step_number > 0"></button>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {

    computed: {
      ...mapGetters({
        settingsId: 'getSettingId'
       }),
      id() {
        if ( this.showOverviewId ) {
          return this.showOverviewId
        }
        if(this.$route.query.overview_id){
          return this.$route.query.overview_id
        }
        if( this.$route && this.$route.meta && this.$route.meta.id ){
          return this.$route.meta.id;
        }
        return null;
      }
    },

    data () {

      return {
        overview_data: {},
        overview_list: [],
        image_url: 'https://app.tenzer.nl',
        step_number: 0,
        overview_step_wise: {},
      }
    },
    mounted () {
      this.$store.commit('SET_OVERVIEW_PATH', this.$route.path);
      this.loadOverviewDetailsList()
    },

    methods: {
      loadOverviewDetailsList () {
        this.$root.$load(true)
        const settingsId = this.settingsId
        this.$http.post(`get-overview-detail-list?setting_id=${settingsId}&id=${this.id}`)
          .then((response) => {
            if (response.status === 200) {
              if( response.data && response.data.dataset && response.data.dataset.overview ){

                this.overview_list = response.data.dataset.overview;
                this.overview_data = response.data.dataset;
                this.overview_step_wise[ this.step_number ] = response.data.dataset;
                if( this.$route.query && this.$route.query.id ){
                  const child_id = parseInt( this.$route.query.id );
                  let query = Object.assign({},this.$route.query);
                  delete query.id;
                  this.$router.replace({query})
                  const childOverview = response.data.dataset.overview.find ( ( overview ) => {
                    if( overview.child && overview.child.length >  0 ) {
                      return overview.child.map( d => d.id ).indexOf( child_id ) !== -1
                    }
                    return false
                  })
                  if( childOverview ) {
                    this.showOverviewChild( childOverview );
                  }
                }
              }
              this.showOverviewId = null;
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      },
      changeTab (e, tabData) {
        this.selected_tab = tabData.id;
      },
      showOverviewChild (overview) {
        this.step_number++;
        this.overview_step_wise[ this.step_number ] = overview;
        this.overview_list = overview.child;
        this.overview_data = overview;
      },
      backScreen(){
        this.step_number--;
        const overview = this.overview_step_wise[ this.step_number ];
        if( overview.overview ){
          this.overview_list = overview.overview;
        }else{
          this.overview_list = overview.child;
        }
        this.overview_data = overview;

      }
    }
  }
</script>
